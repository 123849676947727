import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';

import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';
import Hero from '../components/Hero';
import Wrap from '../components/Wrap';
import Paragraph from '../components/Paragraph';
import FormCTA from '../components/IndexPage/FormCTA';
import Process from '../components/IndexPage/Process';
import WhatWeOffer from '../components/IndexPage/WhatWeOffer';
import Experts from '../components/IndexPage/Experts';
import SlackButton from '../components/SlackButton';
import XArea from '../components/XArea';
import H1 from '../components/H1';

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      active: contentfulActiveLandingPage {
        page: activeLandingPage {
          heroTitle1
          heroTitle2
          heroText {
            heroText
          }
          heroImage {
            fluid(
              maxWidth: 768
              maxHeight: 512
              cropFocus: CENTER
              quality: 90
            ) {
              ...GatsbyContentfulFluid
            }
          }
          buildingTitle
          buildingText {
            buildingText
          }
          showCommunityBlock
          communityTitle
          communityText {
            communityText
          }
          showExperts
          expertsTitle
        }
      }
    }
  `);

  return (
    <Layout css={data.active.page.showCommunityBlock && { marginBottom: 0 }}>
      <SEO title="Home" />
      <Hero
        image={data.active.page.heroImage}
        title={[data.active.page.heroTitle1, data.active.page.heroTitle2]}
        titleIndent="second"
      />
      <Wrap>
        <Paragraph css={{ marginTop: 0, whiteSpace: 'pre-line' }}>
          {data.active.page.heroText.heroText}
        </Paragraph>
        <FormCTA />
      </Wrap>
      <WhatWeOffer />
      <Wrap>
        <H1 css={{ alignText: 'left' }}>{data.active.page.buildingTitle}</H1>
        <Paragraph css={{ whiteSpace: 'pre-line' }}>
          {data.active.page.buildingText.buildingText}
        </Paragraph>
      </Wrap>
      <Process />
      {(data.active.page.showCommunityBlock ||
        data.active.page.showExperts) && (
        <XArea>
          <Wrap>
            {data.active.page.showCommunityBlock && (
              <>
                <H1>{data.active.page.communityTitle}</H1>
                <Paragraph
                  css={{ whiteSpace: 'pre-line', textAlign: 'center' }}
                >
                  {data.active.page.communityText.communityText}
                </Paragraph>
                <SlackButton />
              </>
            )}
            {data.active.page.showExperts && (
              <>
                <H1
                  css={
                    data.active.page.showCommunityBlock &&
                    data.active.page.showExperts && { marginTop: '4em' }
                  }
                  id="experts"
                >
                  {data.active.page.expertsTitle}
                </H1>
                <Experts />
              </>
            )}
          </Wrap>
        </XArea>
      )}
    </Layout>
  );
};

export default IndexPage;
